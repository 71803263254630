import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Grid = makeShortcode("Grid");
const Text = makeShortcode("Text");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <h1 {...{
        "id": "button-guide"
      }}>{`Button Guide`}</h1>
      <Grid my={4} gridTemplateColumns="repeat(4, 15rem)" gridTemplateRows="1fr 4rem 4rem 4rem 4rem 4rem" gridGap={2} mdxType="Grid">
  	<Text mdxType="Text"></Text>
 	<Text mdxType="Text">PRIMARY</Text>
 	<Text mdxType="Text">SECONDARY</Text>
 	<Text mdxType="Text">TERTIARY</Text>
	<Text mdxType="Text">HOSTING</Text>
	<Button color="redDark" mdxType="Button">
		<Text textAlign="center" mdxType="Text">Hosting Button</Text>
	</Button>
	<Button color="redDark" variant="inverse" mdxType="Button">
		<Text textAlign="center" mdxType="Text">Hosting Button</Text>
	</Button>
	<Button color="redDark" variant="link" mdxType="Button">
		<Text textAlign="center" mdxType="Text">Hosting Button</Text>
	</Button>
	<Text mdxType="Text">GENERAL*</Text>
	<Button color="blueDark" mdxType="Button">
		<Text textAlign="center" mdxType="Text">General Button</Text>
	</Button>
	<Button color="blueDark" variant="inverse" mdxType="Button">
		<Text textAlign="center" mdxType="Text">General Button</Text>
	</Button>
	<Button color="blueDark" variant="link" mdxType="Button">
		<Text textAlign="center" mdxType="Text">General Button</Text>
	</Button>
	<Text mdxType="Text">DOMAINS</Text>
	<Button color="greenDark" mdxType="Button">
		<Text textAlign="center" mdxType="Text">Domain Button</Text>
	</Button>
	<Button color="greenDark" variant="inverse" mdxType="Button">
		<Text textAlign="center" mdxType="Text">Domain Button</Text>
	</Button>
	<Button color="greenDark" variant="link" mdxType="Button">
		<Text textAlign="center" mdxType="Text">Domain Button</Text>
	</Button>
	<Text mdxType="Text">SECURITY</Text>
	<Button color="orangeDark" mdxType="Button">
		<Text textAlign="center" mdxType="Text">Security Button</Text>
	</Button>
	<Button color="orangeDark" variant="inverse" mdxType="Button">
		<Text textAlign="center" mdxType="Text">Security Button</Text>
	</Button>
	<Button color="orangeDark" variant="link" mdxType="Button">
		<Text textAlign="center" mdxType="Text">Security Button</Text>
	</Button>
		<Text mdxType="Text">EMAIL</Text>
	<Button color="purpleDark" mdxType="Button">
		<Text textAlign="center" mdxType="Text">Email Button</Text>
	</Button>
	<Button color="purpleDark" variant="inverse" mdxType="Button">
		<Text textAlign="center" mdxType="Text">Email Button</Text>
	</Button>
	<Button color="purpleDark" variant="link" mdxType="Button">
		<Text textAlign="center" mdxType="Text">Email Button</Text>
	</Button>
      </Grid>
      <p>{`*`}{` When representing the entire brand, use blue as the overarching colour.`}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      